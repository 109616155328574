import dayjs from 'dayjs';

import { EMPTY_DEFAULT } from '../config';

import { TimezoneUtils } from './timezone.utils';

export class DateUtilsV2 {
  static readonly FORMATS = {
    // full name of the weekday, e.g., "Monday"
    dddd: 'dddd',
    // August 16, 2018
    LL: 'LL',
    // August 16, 2018 13:00
    LLHHmm: 'LL HH:mm',
    // 2017/03/01 13:00
    LHHmm: 'L HH:mm',
    // 2017/03/01
    L: 'L',
    // 13:00
    HHmm: 'HH:mm',
    // 03/01
    MMDD: 'MM/DD',
    MMDDYYYY: 'MM/DD/YYYY',
    YYYYMMDD: 'YYYY-MM-DD',
    MMM: 'MMM',
    // Jan, Dec
  };

  static formatDateInTimeZone(
    date: Date | string | number,
    extras?: {
      timeZone?: string;
      formatStr?: string;
      startOf?: dayjs.OpUnitType;
      endOf?: dayjs.OpUnitType;
    }
  ) {
    const {
      timeZone = TimezoneUtils.getCurrentTimezone(),
      formatStr = DateUtilsV2.FORMATS.LHHmm,
      startOf,
      endOf,
    } = extras || {};

    try {
      const dayjsObj = typeof date === 'number' ? dayjs.unix(date) : dayjs(date);
      if (startOf) {
        return dayjsObj.tz(timeZone).startOf(startOf).format(formatStr);
      }
      if (endOf) {
        return dayjsObj.tz(timeZone).endOf(endOf).format(formatStr);
      }
      return dayjsObj.tz(timeZone).format(formatStr);
    } catch (error) {
      return EMPTY_DEFAULT;
    }
  }

  static toISOString = (
    date: Date | string,
    extras?: {
      timezone?: string;
      startOf?: dayjs.OpUnitType;
      endOf?: dayjs.OpUnitType;
    }
  ) => {
    const { startOf, endOf, timezone = TimezoneUtils.getCurrentTimezone() } = extras || {};
    if (startOf) {
      return dayjs(date).tz(timezone).startOf(startOf).toISOString();
    }
    if (endOf) {
      return dayjs(date).tz(timezone).endOf(endOf).toISOString();
    }
    return dayjs(date).tz(timezone).toISOString();
  };
}
