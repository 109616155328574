import { useMemo } from 'react';

import dayjs from 'dayjs';

import { TimezoneUtils } from '../utils';

export const useBirthAge = (birthday?: string) => {
  const age = useMemo(() => {
    if (!birthday) return undefined;

    const birthDate = dayjs(birthday).tz(TimezoneUtils.getCurrentTimezone());
    const nowDate = dayjs().tz(TimezoneUtils.getCurrentTimezone());

    return nowDate.diff(birthDate, 'year');
  }, [birthday]);

  return age;
};
