import { useEffect, useMemo } from 'react';

import { DatesRangeUtils } from '@any-ui-react/migrate-dates';

import { HomeMetricOption, useReportSyncCalendar } from '~anyx/feature/report';
import {
  AdsMetricsType,
  ReportChartTab,
  ReportDimensions,
  ReportOrderStatus,
  ReportTab,
} from '~anyx/shared/graphql';
import { DateUtilsV2, TimezoneUtils, useFilters } from '~anyx/shared/utils';

import { HomeFilterInputFilterType, HomePageFiltersParser } from './homePage.filters';

export const useHomeFilter = ({
  accountIds,
  storeId,
}: {
  accountIds: string[];
  storeId?: string;
}) => {
  const timezone = TimezoneUtils.getCurrentTimezone();

  const { range } = useReportSyncCalendar();

  const filtersDefault = useMemo(
    () =>
      HomePageFiltersParser.parse({
        startDate: range?.startDate ?? DatesRangeUtils(timezone).LAST_7_DAYS.start.toISOString(),
        endDate: range.endDate ?? DatesRangeUtils(timezone).LAST_7_DAYS.end.toISOString(),
        orderStatus: ReportOrderStatus.ORDERED,
        masterDataAccountIds: accountIds,
        masterDataStoreIds: storeId ? [storeId] : [],
        marketplaces: [],
        dimensions: ReportDimensions.DAY,
        metric: HomeMetricOption.GROSS_SALES,
        adsMetricsType: AdsMetricsType.GROSS,
        chartTab: ReportChartTab.CHANNEL,
        tab: ReportTab.ALL,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timezone, storeId]
  );

  const filters = useFilters<HomeFilterInputFilterType>(filtersDefault, {
    withPagination: false,
    withLocalStorage: false,
    scope: 'home',
    parser: HomePageFiltersParser.parse,
  });

  const dateVariables = {
    endDate: filters.current?.endDate
      ? DateUtilsV2.formatDateInTimeZone(filters.current?.endDate, { formatStr: '' })
      : '',
    startDate: filters.current?.startDate
      ? DateUtilsV2.formatDateInTimeZone(filters.current?.startDate, { formatStr: '' })
      : '',
  };

  const filterInputs = {
    ...filters.current,
    ...dateVariables,
  };

  // Reset the filter when default values are changed
  useEffect(() => {
    filters.resetFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersDefault]);

  return {
    filters,
    filterInputs,
  };
};
