import {
  CalendarDate,
  CalendarDateTime,
  ZonedDateTime,
  toCalendarDateTime,
} from '@any-ui-react/dates';
import { parseISO, isValid, format } from 'date-fns';
import { enUS, ja, zhCN, ko } from 'date-fns/locale';
import { formatInTimeZone, FormatOptionsWithTZ, toZonedTime, fromZonedTime } from 'date-fns-tz';

import { EMPTY_DEFAULT } from '../config';

import { AnyXLanguage, LanguageUtils } from './language.utils';
import { TimezoneUtils } from './timezone.utils';

export class DateUtils {
  static readonly LOCALES = {
    [AnyXLanguage.EN_US]: enUS,
    [AnyXLanguage.JA_JP]: ja,
    [AnyXLanguage.ZH_CN]: zhCN,
    [AnyXLanguage.KO_KR]: ko,
  };

  // Details
  // https://adasiaholdings.atlassian.net/wiki/spaces/ANYX/pages/3102605334/Date+and+Times
  static readonly FORMATS = {
    // Days of week
    EEEE: 'EEEE',
    // March 1st, 2017
    PPP: 'PPP',
    // March 1st, 2017 13:00
    PPPHHmm: 'PPP HH:mm',
    // 2017/03/01 13:00
    PHHmm: 'P HH:mm',
    // 2017/03/01
    P: 'P',
  };

  static getDateFnsLocale(language?: AnyXLanguage) {
    return DateUtils.LOCALES[language || AnyXLanguage.EN_US];
  }

  static formatDate(
    date: number | Date | string | null,
    formatting?: string,
    language = LanguageUtils.getCurrentLanguage()
  ): string {
    date = typeof date === 'string' ? parseISO(date) : date;
    return date && isValid(date)
      ? format(date, formatting || DateUtils.FORMATS.PHHmm, {
          locale: DateUtils.getDateFnsLocale(language),
        })
      : EMPTY_DEFAULT;
  }

  static formatDateInTimeZone(
    date: Date | string | number,
    extras?: {
      timeZone?: string;
      language?: AnyXLanguage;
      formatStr?: string;
      options?: FormatOptionsWithTZ;
    }
  ) {
    const {
      timeZone = TimezoneUtils.getCurrentTimezone(),
      language = LanguageUtils.getCurrentLanguage(),
      formatStr = DateUtils.FORMATS.PHHmm,
      options,
    } = extras || {};

    try {
      return formatInTimeZone(date, timeZone, formatStr, {
        locale: DateUtils.getDateFnsLocale(language),
        ...options,
      });
    } catch (error) {
      return EMPTY_DEFAULT;
    }
  }

  static toISOStringWithTimezone = (
    date: Date,
    timezone: string = TimezoneUtils.getCurrentTimezone()
  ) => {
    const zonedTime = toZonedTime(date, timezone);
    const utc = DateUtils.formatDateInTimeZone(zonedTime, {
      timeZone: timezone,
      formatStr: 'XXX',
    });
    try {
      const pad = (n: number) => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
      return (
        zonedTime.getFullYear() +
        '-' +
        pad(zonedTime.getMonth() + 1) +
        '-' +
        pad(zonedTime.getDate()) +
        'T' +
        pad(zonedTime.getHours()) +
        ':' +
        pad(zonedTime.getMinutes()) +
        ':' +
        pad(zonedTime.getSeconds()) +
        utc
      );
    } catch (e) {
      throw new Error('toISOStringWithTimezone could not parse date');
    }
  };

  static toISOStringWithoutTimezone = (
    date: Date,
    timezone: string = TimezoneUtils.getCurrentTimezone()
  ) => {
    const zonedTime = toZonedTime(date, timezone);
    try {
      const pad = (n: number) => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
      return (
        zonedTime.getFullYear() +
        '-' +
        pad(zonedTime.getMonth() + 1) +
        '-' +
        pad(zonedTime.getDate())
      );
    } catch (e) {
      throw new Error('toISOStringWithoutTimezone could not parse date');
    }
  };

  static toISOStringStartOfDay = (
    date: CalendarDate | CalendarDateTime | ZonedDateTime | Date,
    tz: string = TimezoneUtils.getCurrentTimezone()
  ) => {
    try {
      if (date instanceof Date) {
        const isoString = date.toISOString();
        const dateWithoutTimezone = isoString.substring(0, 10);
        const startOfDayInUTC = fromZonedTime(
          dateWithoutTimezone,
          tz ?? TimezoneUtils.getCurrentTimezone()
        );

        return startOfDayInUTC.toISOString();
      }

      return toCalendarDateTime(date)
        .set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
        .toDate(tz)
        .toISOString();
    } catch (e) {
      throw new Error('toISOStringStartOfDay couldnt parse date');
    }
  };

  static toISOStringEndOfDay = (
    date: CalendarDate | CalendarDateTime | ZonedDateTime | Date,
    tz: string = TimezoneUtils.getCurrentTimezone()
  ) => {
    try {
      if (date instanceof Date) {
        const zonedDate = toZonedTime(date, tz);
        zonedDate.setHours(23, 59, 59, 999);

        return fromZonedTime(zonedDate, tz).toISOString();
      }

      return toCalendarDateTime(date)
        .set({
          hour: 23,
          minute: 59,
          second: 59,
          millisecond: 999,
        })
        .toDate(tz)
        .toISOString();
    } catch (e) {
      throw new Error('toISOStringEndOfDay couldnt parse date');
    }
  };
}
