import { shiftTimezone } from '@any-ui-react/migrate-dates';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';

import { environmentStore } from '../states';

export class TimezoneUtils {
  static readonly DEFAULT = 'Asia/Tokyo';

  static setTimezone(timeZone: string) {
    if (TimezoneUtils.isValidTimeZone(timeZone)) {
      environmentStore.getState().setTimezone(timeZone);
    }
  }

  static isValidTimeZone(timeZone: string) {
    if (!timeZone) {
      return false;
    }

    try {
      Intl.DateTimeFormat(undefined, { timeZone });
      return true;
    } catch (e) {
      Sentry.captureException(new Error('Error while checking timeZone', { cause: e }));
      return false;
    }
  }

  static toPreferredTimezone(date: Date | string | number, timezone?: string) {
    if (!date || !dayjs(new Date(date)).isValid()) {
      console.error("Couldn't convert to preferred timezone because the date is invalid");
      return new Date();
    }
    return shiftTimezone('add', new Date(date), timezone);
  }

  static getCurrentTimezone() {
    return environmentStore.getState().currentTimezone;
  }
}
