import { style } from './chart.theme';
import { CustomXAxisTick } from './CustomXAxisTick';

export const DEFAULT_Y_AXIS_WIDTH = 40;
export const DEFAULT_WIDTH = 500;
export const DEFAULT_HEIGHT = 200;
export const DEFAULT_BAR_SIZE = 20;
export const DEFAULT_MAX_BAR_SIZE = 40;

export const xAxisTimeScaleConfig = (xAxisInterval: number, theme: 'light' | 'dark' = 'light') => ({
  type: 'number',
  scale: 'time',
  domain: ['dataMin', 'dataMax'],
  interval: xAxisInterval,
  tick: <CustomXAxisTick theme={theme} />,
});

export const xAxisDefaultConfig = (theme: 'light' | 'dark' = 'light') => ({
  tick: { ...style[theme].xAxis.tick },
});

export const getXAxisConfig = (
  isTimeScale = true,
  xAxisInterval?: number,
  theme: 'light' | 'dark' = 'light'
) =>
  isTimeScale
    ? {
        domain: ['dataMin', 'dataMax'],
        interval: xAxisInterval,
        tick: <CustomXAxisTick theme={theme} />,
      }
    : {
        tick: { ...style[theme].xAxis.tick },
      };
