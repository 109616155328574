import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { ChannelUtils, getChannelIcon, singleMarketplaceChannel } from '~anyx/feature/marketplace';
import { getSaleChannelEventColor } from '~anyx/feature/report';
import {
  MarketplaceCalendarEventTargetPayload,
  MarketplaceSaleChannelType,
  SaleChannelType,
} from '~anyx/shared/graphql';
import { NumberWithCurrency, TitledIcon } from '~anyx/shared/ui';
import { DateUtilsV2 } from '~anyx/shared/utils';

export interface EventCalendarChartCardProps {
  saleChannelCountry: string;
  saleChannelType: SaleChannelType;
  startDate: string;
  endDate: string;
  target?: MarketplaceCalendarEventTargetPayload;
  title: string;
}
export const EventCalendarChartCard = (props: EventCalendarChartCardProps) => {
  const { t } = useTranslation('report');
  const { saleChannelCountry, saleChannelType, startDate, endDate, target, title } = props;
  return (
    <div
      className="group relative mb-1 flex cursor-pointer items-center rounded-lg border p-1 leading-none shadow last:mb-0"
      style={{
        background: getSaleChannelEventColor(saleChannelType).background,
        borderColor: getSaleChannelEventColor(saleChannelType).border,
      }}
    >
      <div
        className={classNames({
          'text-black': saleChannelType === MarketplaceSaleChannelType.Manual,
        })}
      >
        <TitledIcon
          icon={getChannelIcon(saleChannelType, 'fa', { width: '1rem', height: '1rem' })}
          text={
            !singleMarketplaceChannel(ChannelUtils.asMarketplaceSaleChannelType(saleChannelCountry))
              ? saleChannelCountry
              : null
          }
        />
      </div>
      <div className="overflow-hidden pl-1">
        <div className="text-gray-9 flex items-baseline">
          <div className="text-3xs line-clamp-1 block max-w-xs truncate font-normal">{title}</div>
          <div className="text-6xs font-light">
            (
            {DateUtilsV2.formatDateInTimeZone(startDate, {
              formatStr: DateUtilsV2.FORMATS.MMDDYYYY,
            })}
            -
            {DateUtilsV2.formatDateInTimeZone(endDate, {
              formatStr: DateUtilsV2.FORMATS.MMDDYYYY,
            })}
            )
          </div>
        </div>
        <div className="text-gray-7 text-5xs mt-px flex items-baseline font-normal">
          <div className="">{t('report.page.eventCalendar.targetGrossSales')}:</div>
          <NumberWithCurrency
            amount={target?.revenue}
            currency={target?.currency}
            amountClassName="ml-1"
          />
        </div>
      </div>
    </div>
  );
};
