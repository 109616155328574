import classNames from 'classnames';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { CurrenciesEnum, EMPTY_DEFAULT, NumberUtils } from '~anyx/shared/utils';

import { NumberWithCurrency, NumberWithFormat, NumberWithPercentage } from '../../atoms';

import { style } from './chart.theme';

type MultiValueTooltipProps = TooltipProps<ValueType, NameType> & {
  theme?: 'light' | 'dark';
  position?: 'bottom' | 'center' | 'top';
  showTarget?: boolean;
  footerContent?: React.ReactNode;
  columns?: ContentColumn;
  dataKeyTranslation: (dataKey?: string | number) => string;
};

export enum ContentColumn {
  ONE = 1,
  TWO = 2,
  THREE = 3,
}
export enum NumberFormat {
  PRICE = 'price',
  PERCENTAGE = 'percentage',
  NUMBER = 'number',
}
export const MultiValueTooltip = ({
  active,
  payload,
  label: date,
  theme = 'dark',
  position = 'center',
  showTarget = false,
  footerContent,
  dataKeyTranslation,
  columns = ContentColumn.TWO,
}: MultiValueTooltipProps) => {
  const formatNumber = ({
    numberFormat,
    value,
    currency,
  }: {
    numberFormat: string;
    value: number;
    currency: CurrenciesEnum;
  }) => {
    switch (numberFormat) {
      case NumberFormat.PRICE:
        return (
          <NumberWithCurrency
            amount={value}
            currency={currency}
            currencyClassName="ml-0.5 text-4xs"
          />
        );
      case NumberFormat.PERCENTAGE:
        return <NumberWithPercentage amount={value} />;
      default:
        return NumberUtils.isNumber(value) ? <NumberWithFormat amount={+value} /> : EMPTY_DEFAULT;
    }
  };
  if (active && payload) {
    const { total, name, currency, numberFormat, totalTarget } = payload[0]?.payload || {};

    return (
      <div className="max-w-sm leading-8">
        <div
          className={classNames(
            'shadow-tooltip bg-chartTooltip text-4xs relative translate-x-1 rounded p-2 font-semibold text-white opacity-95',
            {
              '-translate-y-[calc(80%+1rem)]': position === 'top',
              '-translate-y-[calc(20%+1rem)]': position === 'center',
              'translate-y-[calc(80%+1rem)]': position === 'bottom',
            }
          )}
        >
          <p className="mb-2">
            {dayjs(new Date(date)).isValid()
              ? `${dayjs(date).format('YYYY/MM/DD')} (${dayjs(date).format('dddd')})`
              : date}
          </p>
          {!!total && (
            <p
              className={classNames({
                'mb-4': !(showTarget && totalTarget >= 0),
              })}
            >
              {`${name} (${t('shared.field.total.name', {
                ns: 'shared',
              })})`}
              <span className="ml-2 text-sm">
                {formatNumber({ numberFormat, value: total, currency })}
              </span>
            </p>
          )}
          {showTarget && totalTarget >= 0 && (
            <p className="mb-4">
              {t('report.page.eventCalendar.targetGrossSales', {
                ns: 'report',
              })}
              <span className="ml-2 text-sm">
                {formatNumber({
                  numberFormat: NumberFormat.PRICE,
                  value: totalTarget,
                  currency,
                })}
              </span>
            </p>
          )}
          <div
            className={classNames('grid grid-cols-1 gap-x-4 gap-y-2', {
              'md:grid-cols-1': columns === ContentColumn.ONE,
              'md:grid-cols-2': columns === ContentColumn.TWO,
              'md:grid-cols-3': columns === ContentColumn.THREE,
            })}
          >
            {payload.map((entry, index) => {
              if (entry.dataKey === 'totalTarget') return null;
              return payload.length > 1 ? (
                <div key={index} className="col-span-1">
                  <div className="flex min-w-0 items-start">
                    <div
                      className="mr-1 h-2.5 w-2.5 shrink-0 rounded-full"
                      style={{ backgroundColor: entry.color }}
                    />
                    <p className="truncate capitalize leading-4">
                      {dataKeyTranslation(entry.dataKey)}
                    </p>
                  </div>
                  <div className=" ml-1 leading-4" style={{ color: style[theme].legend.text }}>
                    {formatNumber({ numberFormat, value: entry.value as number, currency })}
                  </div>
                </div>
              ) : (
                <div key={index} className="flex items-center gap-2">
                  <span>{name}</span>
                  <span>
                    {formatNumber({ numberFormat, value: entry.value as number, currency })}
                  </span>
                </div>
              );
            })}
          </div>
          {footerContent}
        </div>
      </div>
    );
  }
  return null;
};
