import { useMemo } from 'react';

import { DatesRangeUtils } from '@any-ui-react/migrate-dates';

import { DdiHomeMetricOption, useReportSyncCalendar } from '~anyx/feature/report';
import { ReportDimensions } from '~anyx/shared/graphql';
import { DateUtilsV2, TimezoneUtils, useFilters } from '~anyx/shared/utils';

import { DdiHomePageFiltersParser } from './ddiHomePage.filters';
import { DdiHomeFilterInputFilterType } from './ddiHomePage.filters';

export const useDdiHomeFilter = ({
  accountId,
  storeId,
}: {
  accountId?: string;
  storeId?: string;
}) => {
  const timezone = TimezoneUtils.getCurrentTimezone();

  const { range } = useReportSyncCalendar();
  const filtersDefault = useMemo(
    () =>
      DdiHomePageFiltersParser.parse({
        startDate: range?.startDate ?? DatesRangeUtils(timezone).LAST_7_DAYS.start.toISOString(),
        endDate: range?.endDate ?? DatesRangeUtils(timezone).LAST_7_DAYS.end.toISOString(),
        masterDataStoreIds: storeId ? [storeId] : [],
        marketplaces: [],
        dimensions: ReportDimensions.DAY,
        metric: DdiHomeMetricOption.GROSS_SALES,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timezone, accountId, storeId]
  );

  const filters = useFilters<DdiHomeFilterInputFilterType>(filtersDefault, {
    withPagination: false,
    withLocalStorage: false,
    scope: 'home',
    parser: DdiHomePageFiltersParser.parse,
  });

  const dateVariables = {
    endDate: filters.current?.endDate
      ? DateUtilsV2.formatDateInTimeZone(filters.current?.endDate, { formatStr: '' })
      : '',
    startDate: filters.current?.startDate
      ? DateUtilsV2.formatDateInTimeZone(filters.current?.startDate, { formatStr: '' })
      : '',
  };

  const filterInputs = {
    ...filters.current,
    ...dateVariables,
  };

  return {
    filters,
    filterInputs,
  };
};
