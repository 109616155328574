import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { AnyUIProvider } from '@any-ui-react/core';

import { ANYCHAT_THEME, ANYLOGI_THEME, ANYX_THEME, Theme } from '../themes';

export function ThemeProvider({
  children,
  theme,
}: {
  children: React.ReactNode | React.ReactNode[];
  theme: Theme;
}) {
  const { t } = useTranslation('shared');
  const [type, setType] = useState<Theme>(theme);

  const {
    label,
    other,
    theme: themeProps,
    ...rest
  } = useMemo(() => {
    if (type === Theme.ANYLOGI) {
      return ANYLOGI_THEME;
    }
    if (type === Theme.ANYCHAT) {
      return ANYCHAT_THEME;
    }
    return ANYX_THEME;
  }, [type]);

  useEffect(() => {
    setType(theme);
  }, [theme]);

  return (
    <AnyUIProvider
      {...rest}
      theme={{
        fontFamily:
          '"Inter var", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Segoe UI Emoji", "Segoe UI Symbol", "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "Android Emoji", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif',
        ...themeProps,
      }}
      label={{
        noOptions: t('shared.select.noOption'),
        apply: t('shared.button.apply'),
        cancel: t('shared.button.cancel'),
        ...label,
      }}
      other={{
        ...other,
      }}
    >
      <Helmet htmlAttributes={{ 'data-theme': type }} />
      {children}
    </AnyUIProvider>
  );
}
